<script setup lang="ts">
import { object, string } from "yup";
import { ref } from "vue";
import NewsletterInput from "./components/NewsletterInput.vue";

interface NewsletterForm {
  firstname: string;
  email: string;
}

const { $tt } = useNuxtApp();

const { handleSubmit, resetForm } = useForm<NewsletterForm>({
  validationSchema: object({
    firstname: string().required($tt("landing.newsletter.firstNameRequired")),
    email: string()
      .email($tt("landing.newsletter.invalidEmail"))
      .required($tt("landing.newsletter.emailRequired")),
  }),
});

const textMessage = ref<string>("");

const onSubmit = handleSubmit(async (values) => {
  const newsletter = await useFetch("/api/newsletter", {
    method: "POST",
    body: values,
  });
  if (newsletter.data.value) {
    textMessage.value = $tt("landing.newsletter.success");
  } else {
    textMessage.value = $tt("landing.newsletter.error");
  }
  reset();
  setTimeout(() => {
    textMessage.value = "";
  }, 3000);
});

function reset() {
  resetForm();
}
</script>
<template>
  <div class="bg-gunmetal">
    <div class="container px-4 md:px-10 xl:px-20 py-16">
      <div
        class="flex justify-center flex-col gap-y-10 md:flex-row md:gap-y-0 md:gap-x-10"
      >
        <div class="flex-initial w-full lg:w-[340px]">
          <h2
            class="font-poppins text-3xl font-normal text-white mb-2 mr-[50px]"
          >
            {{ $tt("landing.newsletter.title") }}
          </h2>
          <h3
            class="font-poppins text-xl font-normal text-auro-metal-saurus mr-[50px]"
          >
            {{ $tt("landing.newsletter.content") }}
          </h3>
        </div>
        <div class="flex-initial w-full lg:w-8/12 lg:max-w-[680px]">
          <form
            class="flex flex-col gap-y-10"
            autocomplete="off"
            @submit="onSubmit"
          >
            <NewsletterInput
              :placeholder="$tt('landing.newsletter.name')"
              name="firstname"
            />
            <NewsletterInput
              :placeholder="$tt('landing.newsletter.email')"
              name="email-newsletter"
              type="email"
            />
            <p
              v-if="textMessage"
              class="font-poppins text-base font-normal text-white pl-2"
            >
              {{ textMessage }}
            </p>
            <div class="flex justify-start items-center">
              <button
                class="rounded-lg py-[10px] px-5 text-black bg-[#F2CA50] font-medium hover:bg-primary-500"
              >
                {{ $tt("landing.newsletter.submit") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
