<script setup lang="ts">
import { useField } from "vee-validate";

interface NewsletterInputProps {
  name: string;
}

defineOptions({
  inheritAttrs: true,
});

const props = defineProps<NewsletterInputProps>();

const { value, errorMessage } = useField(() => props.name);
</script>
<template>
  <div class="relative w-full">
    <input
      :id="name"
      v-model="value"
      class="w-full text-xl text-white py-7 px-10 rounded-[10px] bg-dark-slate-gray placeholder-auro-metal-saurus"
      v-bind="$attrs"
      :name="name"
    />
    <p
      v-show="errorMessage"
      class="absolute text-red-600 -bottom-7 left-2 text-sm"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
